<template>
  <div
    class="snt-navbar">
    <v-app-bar
      id="app-bar"
      :app="fixed"
      :style="{
        zIndex: 10
      }"
      :width="width"
      :height="height"
      :max-height="height"
      elevation="0"
      color="white"
      flat>
      <v-container class="py-0 my-n1 fill-height flex-nowrap">
        <template v-if="!mobile">
          <Brand
            :site-name="siteName"
            :domain="domain" />
          <v-tabs
            v-if="!$apollo.queries.me.loading"
            :value="activeDomain"
            active-class="font-weight-medium"
            slider-size="3"
            slider-color="primary"
            align-with-title>
            <v-tab
              v-for="(link, i) in links"
              v-show="link.href"
              :key="`navigation-${i}`"
              :ripple="false"
              :href="link.href"
              :style="{ width: link.width }"
              class="body-1 px-2">
              <component
                :is="link.icon"
                class="mr-2"
                width="24"
                height="24" />
              <v-badge
                :value="(['/xxx']).includes(link.href)"
                color="primary"
                content="ใหม่">
                {{ link.text }}
              </v-badge>
            </v-tab>
          </v-tabs>
          <v-spacer />
          <div
            v-if="!$apollo.loading"
            class="d-flex align-center gap-2">
            <v-btn
              v-if="isShipnity"
              :href="`https://blog.${domain}/how-to/`"
              :ripple="false"
              target="_blank"
              class="body-1"
              height="40"
              rounded
              text
              link>
              คู่มือ
            </v-btn>
            <Help>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  :ripple="false"
                  width="40"
                  height="40"
                  icon
                  v-on="on">
                  <component
                    :is="helpIcon"
                    width="24"
                    height="24" />
                </v-btn>
              </template>
            </Help>
            <v-btn
              v-if="me.role.accessSetting"
              :href="`/setting`"
              :ripple="false"
              :class="{
                'v-btn--active': activeSetting,
                'primary--text': activeSetting,
              }"
              tag="a"
              width="40"
              height="40"
              icon>
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
            <Notify
              :api-url="apiUrl"
              :items="notificationList.nodes"
              :loading="$apollo.queries.notificationList.loading">
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  :ripple="false"
                  width="40"
                  height="40"
                  icon
                  v-on="on">
                  <v-badge
                    :value="notificationList.nodesCount"
                    :content="notificationList.nodesCount"
                    color="error"
                    overlap
                    bottom>
                    <component
                      :is="notificationsIcon"
                      width="24"
                      height="24" />
                  </v-badge>
                </v-btn>
              </template>
            </Notify>
            <Account :domain="domain">
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  :ripple="false"
                  class="body-1 font-weight-regular"
                  height="60px"
                  tag="a"
                  tile
                  text
                  v-on="on">
                  <div
                    :style="{ maxWidth: '140px' }"
                    class="text-truncate">
                    {{ me.shop.name }}
                  </div>
                  <v-icon
                    color="secondary"
                    right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
            </Account>
          </div>
        </template>
        <template v-else>
          <a
            href="/"
            class="text-decoration-none font-weight-medium text-capitalize no-user-select secondary--text mr-1"
            text>
            {{ siteName }}
          </a>
          <v-app-bar-nav-icon
            class="ml-1"
            color="secondary"
            height="40"
            width="40"
            @click="setDrawer(true)" />
          <v-spacer />
          <div class="d-flex gap-1">
            <div>
              <QuickMenu />
            </div>
            <v-btn
              v-if="me.role.accessSetting"
              :href="`/setting/`"
              :ripple="false"
              width="40"
              height="40"
              icon>
              <v-icon color="secondary">
                mdi-cog
              </v-icon>
            </v-btn>
            <div>
              <Notify
                :api-url="apiUrl"
                :items="notificationList.nodes"
                :loading="$apollo.queries.notificationList.loading">
                <template #activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    :ripple="false"
                    width="40"
                    height="40"
                    icon
                    v-on="on">
                    <v-badge
                      :value="notificationList.nodesCount"
                      :content="notificationList.nodesCount"
                      color="error"
                      overlap
                      bottom>
                      <component
                        :is="notificationsIcon"
                        width="24"
                        height="24" />
                    </v-badge>
                  </v-btn>
                </template>
              </Notify>
            </div>
            <div>
              <Account :domain="domain">
                <template #activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    :ripple="false"
                    width="40"
                    height="40"
                    icon
                    v-on="on">
                    <v-icon color="secondary">
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
              </Account>
            </div>
          </div>
        </template>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :style="{
        zIndex: 300
      }"
      absolute
      temporary>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ siteName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ me.shop.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list>
      <v-list
        nav
        dense>
        <v-list-item-group
          v-model="group"
          color="primary">
          <v-list-item
            v-for="(link, i) in links"
            :key="`navigation-drawer-${i}`"
            :href="link.href">
            <v-list-item-icon>
              <component
                :is="link.icon"
                width="24"
                height="24" />
            </v-list-item-icon>
            <v-list-item-title>
              <v-badge
                :value="(['/xxx']).includes(link.href)"
                color="primary"
                content="ใหม่">
                {{ link.text }}
              </v-badge>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import psl from 'psl'
import {
  VAppBar,
  VContainer,
  VTabs,
  VTab,
  VIcon,
  VSpacer,
  VAppBarNavIcon,
  VBtn,
  VBadge,
  VDivider,
  VNavigationDrawer,
  VList,
  VListItemGroup,
  VListItemIcon,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VListItemContent
} from 'vuetify/lib'
import { GET_ME, GET_NOTI_LIST } from '../../resources/graphql'
import Brand from './components/Brand.vue'
import Account from './components/Account.vue'
import Help from './components/Help.vue'
import Notify from './components/Notify.vue'
import QuickMenu from './components/QuickMenu.vue'
import orderIcon from '../../assets/svg/icons/order.svg'
import shoppingIcon from '../../assets/svg/icons/shopping-basket.svg'
import assessmentIcon from '../../assets/svg/icons/assessment.svg'
import personIcon from '../../assets/svg/icons/person.svg'
import historyIcon from '../../assets/svg/icons/history.svg'
import chatIcon from '../../assets/svg/icons/chat.svg'
import helpIcon from '../../assets/svg/icons/help.svg'
import notificationsIcon from '../../assets/svg/icons/notifications.svg'

export default {
  components: {
    VAppBar,
    VTabs,
    VTab,
    VContainer,
    VIcon,
    VSpacer,
    VBtn,
    VBadge,
    VDivider,
    VAppBarNavIcon,
    VNavigationDrawer,
    VList,
    VListItemGroup,
    VListItemIcon,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VListItemContent,
    Brand,
    Help,
    Account,
    Notify,
    QuickMenu
  },
  props: {
    apiUrl: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '60px'
    },
    active: {
      type: [String, Number],
      default: -1
    },
    fixed: Boolean
  },
  apollo: {
    me: {
      query: GET_ME,
      variables () {
        return {
          uri: this.apiUrl
        }
      }
    },
    notificationList: {
      query: GET_NOTI_LIST,
      variables () {
        return {
          page: 1,
          perPage: 2,
          uri: this.apiUrl
        }
      }
    }
  },
  data () {
    return {
      drawer: false,
      group: null,
      me: {
        role: {
          productView: false,
          reportView: false,
          historyAll: false,
          accessSetting: false
        },
        shop: {
          name: ''
        }
      },
      notificationList: {
        hasNextPage: 0,
        hasPreviousPage: 0,
        nodes: [],
        pagesCount: 0,
        nodesCount: 0
      },
      notificationsIcon,
      helpIcon
    }
  },
  computed: {
    mobile () {
      return this.$vuetify.breakpoint.mobile
    },
    domain () {
      return psl.parse(window.location.hostname).domain || 'shipnity.com'
    },
    siteName () {
      return this.$_.capitalize(this.domain.split('.')[0])
    },
    isShipnity () {
      return this.domain === 'shipnity.com'
    },
    links () {
      const links = [
        {
          href: '/orders',
          icon: orderIcon,
          text: 'ออเดอร์',
          width: '110px'
        },
        !this.me.role.productView || {
          href: '/product',
          icon: shoppingIcon,
          text: 'สินค้า',
          width: '90px'
        },
        !this.me.role.reportView || {
          href: '/reports/table_report',
          icon: assessmentIcon,
          text: 'รายงาน',
          width: '110px'
        },
        // {
        //   href: '/contacts',
        //   icon: personIcon,
        //   text: 'ผู้ติดต่อ',
        //   width: '110px'
        // },
        {
          href: '/contact',
          icon: personIcon,
          text: 'ผู้ติดต่อ',
          width: '110px'
        },
        !this.me.role.historyAll || {
          href: '/data',
          icon: historyIcon,
          text: 'ประวัติ',
          width: '100px'
        },
        {
          href: '/whalebox',
          icon: chatIcon,
          text: 'แชท/ไลฟ์',
          width: '120px'
        },
        {
          href: ''
        }
      ]
      return links.filter((item) => this.$_.isObject(item))
    },
    activeDomain () {
      switch (this.active) {
        case 'order': return '/orders'
        case 'product': return '/product'
        case 'report': return '/reports/table_report'
        case 'contact': return '/contact'
        case 'data': return '/data'
        case 'whalebox': return '/whalebox'
        default: return this.links[this.active]?.href || ''
      }
    },
    activeSetting () {
      return this.active === 'setting'
    }
  },
  methods: {
    setDrawer (drawer = false) {
      this.drawer = drawer
    }
  }
}
</script>

<style lang="scss" scoped>
  .snt-navbar {
    .v-toolbar__content > .container.fill-height {
      height: inherit;
      margin-top: -4px;
      margin-bottom: -4px;
      .v-tabs {
        height: inherit;
        margin-top: -4px;
        margin-bottom: -4px;
        ::v-deep {
          .v-slide-group.v-tabs-bar {
            background-color: inherit;
            height: inherit;
          }
          .v-tabs-slider-wrapper {
            padding: 0 6px 0 8px;
          }
        }
      }
    }
  }
</style>
