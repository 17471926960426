import gql from 'graphql-tag'

export const SIGN_OUT = gql`
  mutation {
    signOutUser(input: {}) {
      errors
    }
  }
`
export default {
  SIGN_OUT
}
