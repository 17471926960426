<template>
  <v-menu
    v-model="menu"
    nudge-top="-4px"
    offset-y
    tile>
    <template v-slot:activator="{ attrs, on }">
      <slot
        name="activator"
        :attrs="attrs"
        :disabled="fullscreen"
        :on="on">
        <div
          v-bind="attrs"
          v-on="on">
          Notify
        </div>
      </slot>
    </template>
    <v-card width="300">
      <v-card-text>
        <div
          v-if="loading"
          class="grey--text text-center">
          กำลังโหลด...
        </div>
        <template v-if="items.length">
          <template v-for="(item, i) in items">
            <div
              :key="`noti-card-${i}`"
              class="d-flex gap-2 my-2 caption"
              @click.stop>
              <div>
                <v-icon color="error">
                  mdi-alert
                </v-icon>
              </div>
              <div>
                <div>{{ item.heading }}</div>
                <div class="grey--text">
                  {{ item.description }}
                </div>
                <v-btn
                  :href="item.linkTo"
                  class="caption my-2"
                  color="primary"
                  depressed
                  rounded
                  small>
                  {{ item.linkName }}
                </v-btn>
              </div>
            </div>
            <v-divider :key="`noti-divider-${i}`" />
          </template>
          <div class="text-center mt-3">
            <NotifyFullscreen
              v-model="fullscreen"
              :api-url="apiUrl" />
          </div>
        </template>
        <div
          v-else
          class="d-flex justify-center align-center grey--text py-5">
          ไม่มีการแจ้งเตือน
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {
  VMenu,
  VIcon,
  VBtn,
  VCard,
  VCardText,
  VDivider
} from 'vuetify/lib'
import NotifyFullscreen from './NotifyFullscreen.vue'

export default {
  components: {
    VMenu,
    VIcon,
    VBtn,
    VCard,
    VCardText,
    VDivider,
    NotifyFullscreen
  },
  props: {
    apiUrl: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default: () => ([])
    },
    domain: {
      type: String,
      default: 'Untitled'
    },
    loading: Boolean
  },
  data () {
    return {
      menu: false,
      fullscreen: false
    }
  },
  computed: {
    logo () {
      return this.domain.split('.')[0]
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-menu__content {
    border-radius: 4px !important;
    box-shadow: 0px 0px 5px rgba(86,96,117,0.2) !important;
  }
</style>
