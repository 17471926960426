<template>
  <div></div>
  <!-- <div
    v-if="showMessage"
    id="draggable-container"
    ref="draggableContainer"
    :style="{ zIndex }"
    class="snt-bubble"
    @mousedown="dragMouseDown">
    <div
      class="snt-bubble__event snt-bubble__close"
      @click="close()"></div>
    <div
      class="snt-bubble__event snt-bubble__btn"
      @click="goTo()"></div>
    <img
      class="snt-bubble__bg"
      :src="bgImage" />
  </div> -->
</template>

<script>

import bgImage from '../../assets/imgs/space-ship.gif'

export default {
  props: {
    zIndex: {
      type: [String, Number],
      default: 100
    }
  },
  data () {
    return {
      bgImage,
      showMessage: false,
      messageKey: 'space-ship',
      positions: {
        moving: false,
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  mounted () {
    const storage = localStorage.getItem('local:shipnity')
    if (!storage) {
      localStorage.setItem('local:shipnity', JSON.stringify({
        bubble: {
          byId: {
            default: []
          }
        }
      }))
    }
    this.showMessage = this.getStorage()
  },
  methods: {
    getStorage () {
      const storage = JSON.parse(localStorage.getItem('local:shipnity'))
      const userId = 'default'
      const messages = storage.bubble.byId[userId] || []
      const message = messages.find((item) => item.key === this.messageKey)
      return !message || !this.$dayjs(message.at).isToday()
    },
    addStorage () {
      const storage = JSON.parse(localStorage.getItem('local:shipnity'))
      const userId = 'default'
      const messages = storage.bubble.byId[userId] || []
      messages.unshift({
        key: this.messageKey,
        at: new Date()
      })
      storage.bubble.byId = {
        ...storage.bubble.byId,
        [userId]: this.$_.uniqBy(messages, 'key')
      }
      localStorage.setItem('local:shipnity', JSON.stringify(storage))
    },
    close () {
      this.addStorage()
      this.showMessage = this.getStorage()
    },
    goTo () {
      if (this.positions.moving) return
      window.open('https://www.shipnity.com/space_ship/', '_blank')
    },
    dragMouseDown (event) {
      this.positions.moving = false
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag (event) {
      this.positions.moving = true
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY }px`
      this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX }px`
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .snt-bubble {
    position: fixed;
    width: 120px;
    height: 114px;
    // top: calc(100vh - 130px);
    right: 24px;
    bottom: 24px;
    &__bg {
      width: 100%;
    }
    &__event {
      position: absolute;
      cursor: pointer;
      // border: 1px solid red;
    }
    &__close {
      width: 16px;
      height: 16px;
      top: 8px;
      right: 2px;
    }
    &__btn {
      width: 100px;
      height: 80px;
      bottom: 8px;
      left: 12px;
    }
  }
</style>
