<template>
  <v-dialog
    v-model="dialog"
    width="600px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        :attrs="attrs"
        :on="on">
        <div
          v-bind="attrs"
          v-on="on">
          Help
        </div>
      </slot>
    </template>
    <v-card>
      <v-card-title>
        <v-spacer />
        <div class="display-1 mr-n6">
          สอบถามได้ที่
        </div>
        <v-spacer />
        <v-btn
          icon
          small
          @click="setDialog(false)">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="d-flex align-center mt-4 mb-2">
          <v-icon class="mr-2">
            mdi-phone
          </v-icon>
          <div class="subtitle-1">
            โทรศัพท์ (จันทร์ - ศุกร์ 9.00 - 18.00 น.)
          </div>
        </div>
        <div class="ml-8 mb-4">
          <div class="text-h6 my-2">
            การใช้งาน / แจ้งปัญหาการใช้งาน (ฝ่ายบริการลูกค้า)
          </div>
          <div class="body-1 font-weight-light">
            065 226 8844
          </div>
          <div class="body-1 font-weight-light">
            095 684 6223
          </div>
          <div class="body-1 font-weight-light">
            084 218 4883
          </div>
          <div class="body-1 font-weight-light">
            063 981 1885
          </div>
          <div class="text-h6 my-2">
            รายละเอียดแพ็คเกจ บริการต่างๆ (ฝ่ายขาย)
          </div>
          <div class="body-1 font-weight-light">
            083 925 9650
          </div>
          <div class="body-1 font-weight-light">
            062 747 0202
          </div>
          <div class="body-1 font-weight-light">
            063 442 7423
          </div>
        </div>
        <div class="d-flex align-center">
          <v-icon class="mr-2">
            mdi-facebook
          </v-icon>
          <div class="subtitle-1">
            Facebook
          </div>
        </div>
        <div class="ml-8 mb-4">
          <a
            target="_blank"
            href="https://www.facebook.com/shipnity"
            class="headline font-weight-light">
            facebook.com/shipnity
          </a>
        </div>
        <div class="d-flex align-center my-2">
          <v-icon class="mr-2">
            mdi-chat-outline
          </v-icon>
          <div class="subtitle-1">
            <span class="mr-2">Line</span>
            <span class="primary--text">@shipnity</span>
          </div>
        </div>
        <div class="ml-8 mb-4">
          <v-btn
            href="http://line.me/ti/p/%40shipnity"
            target="_blank"
            class="btn btn-primary"
            color="#05B305"
            depressed
            dark>
            <v-icon left>
              mdi-plus
            </v-icon>
            Add Friend
          </v-btn>
        </div>
        <div class="mt-8">
          <div class="title mb-2">
            เวลาทำการ
          </div>
          <div class="body-2">
            จันทร์ - ศุกร์ 9.00 - 18.00 น.<br>
            เสาร์ - อาทิตย์ 10.00 - 16.00 น. (สอบถามทางแชทเท่านั้น)<br>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VSpacer,
  VDivider,
  VIcon,
  VBtn
} from 'vuetify/lib'

export default {
  components: {
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VSpacer,
    VIcon,
    VDivider,
    VBtn
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
