import Vue from 'vue'

export const formatDate = (value) => {
  if (!value) return ''
  return Vue.prototype.$dayjs(new Date(value)).format('DD/MM/YYYY')
}
export const formatTime = (value) => {
  if (!value) return ''
  return Vue.prototype.$dayjs(new Date(value)).format('HH:mm')
}
export const formatDateTime = (value) => {
  if (!value) return ''
  return Vue.prototype.$dayjs(new Date(value)).format('DD/MM/YYYY HH:mm')
}
export const monthItems = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม'
]
export const formatDateString = (value) => {
  const d = new Date(value)
  const day = d.getDate()
  const year = d.getFullYear()
  const month = d.getMonth()
  return `วันที่ ${day} ${monthItems[month]} ปี ${year}`
}

export const formatTimeString = (value) => {
  const d = new Date(value)
  const hours = d.getHours()
  const minutes = d.getMinutes()
  return `เวลา ${hours}:${minutes} น.`
}

export const formatDateTimeString = (value) => `${formatDateString(value)} ${formatTimeString(value)}`

export default {
  formatDateTime,
  formatDate,
  formatTime,
  formatDateString
}
