<template>
  <v-menu
    v-model="menu"
    nudge-top="-4px"
    offset-y
    tile>
    <template v-slot:activator="{ attrs, on }">
      <slot
        name="activator"
        :attrs="attrs"
        :on="on">
        <div
          v-bind="attrs"
          v-on="on">
          My Account
        </div>
      </slot>
    </template>
    <v-list
      min-width="180"
      max-width="240"
      dense>
      <v-list-item
        v-if="isShipnity"
        :href="`https://blog.${domain}/how-to/`"
        :ripple="false"
        class="body-2"
        target="_blank"
        link>
        คู่มือ
      </v-list-item>
      <v-list-item
        v-if="isShipnity"
        :href="`https://package.${domain}/?from=pro`"
        :ripple="false"
        class="body-2"
        link>
        สมัครแพ็คเกจ
      </v-list-item>
      <v-list-item
        v-if="isShipnity"
        :href="`/priceplan/buy/buy_sms`"
        :ripple="false"
        class="body-2"
        link>
        เติม Credit SMS
      </v-list-item>
      <Help v-if="isShipnity && mobile">
        <template #activator="{ attrs, on }">
          <v-list-item
            v-bind="attrs"
            :ripple="false"
            class="body-2"
            icon
            v-on="on">
            ติดต่อสอบถาม
          </v-list-item>
        </template>
      </Help>
      <v-divider class="my-1" />
      <v-list-item
        :href="`/users/edit`"
        :ripple="false"
        class="body-2"
        link>
        ตั้งค่าบัญชี
      </v-list-item>
      <v-list-item
        :ripple="false"
        class="body-2"
        link
        @click="logout()">
        ออกจากระบบ
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  VMenu,
  VDivider,
  VList,
  VListItem
} from 'vuetify/lib'
import { SIGN_OUT } from '../../../resources/graphql'
import Help from './Help.vue'

export default {
  components: {
    VMenu,
    VDivider,
    VList,
    VListItem,
    Help
  },
  props: {
    domain: {
      type: String,
      default: 'Untitled'
    }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    isShipnity () {
      return this.domain === 'shipnity.com'
    },
    mobile () {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    async logout () {
      await this.$apollo.mutate({
        mutation: SIGN_OUT
      })
      window.location.href = '/'
    }
  }
}
</script>
<style lang="scss" scoped>
  .v-menu__content {
    border-radius: 4px !important;
    box-shadow: 0px 0px 5px rgba(86,96,117,0.2) !important;
    > .v-list {
      ::v-deep {
        padding: 8px;
        .v-list-item {
          padding: 0 8px;
          margin-bottom: 4px;
          border-radius: 4px;
          &::before {
            border-radius: 4px;
          }
          &:hover::before {
            opacity: 0.1;
          }
          &.v-list-item--link:hover {
            color: var(--v-primary-base) !important;
          }
          .v-list-item__title {
            font-size: 1rem !important;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
          }
        }
      }
    }
  }
</style>
