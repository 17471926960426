import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)
export default new Vuetify({
  theme: {
    disable: process.env.VUE_APP_MODE === 'PRODUCTION',
    light: true,
    dark: false,
    themes: {
      light: {
        primary: '#1BA7E1',
        error: '#DE6B4F',
        success: '#19C788',
        warning: '#EDA437',
        secondary: '#6C6C6C',
        background: '#F4F6FA',
        shopee: '#F8551F',
        lazada: '#0304B0',
        grey: '#B5B5B5',
        accent: '#ff7a1a'
      },
      dark: {
        primary: '#1BA7E1',
        error: '#F04D4D',
        success: '#2AB59C',
        warning: '#EDA437',
        secondary: '#FFFFFF'
      }
    }
  }
})
