import gql from 'graphql-tag'

export const GET_ME = gql`
  query  {
    me {
      id
      role {
        id
        productView
        reportView
        historyAll
        accessSetting
      }
      shop {
        ...on Shop {
          name
        }
        ...on Reseller {
          name
        }
      }
    }
  }
`

export default {
  GET_ME
}
