var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"snt-navbar"},[_c('v-app-bar',{style:({
      zIndex: 10
    }),attrs:{"id":"app-bar","app":_vm.fixed,"width":_vm.width,"height":_vm.height,"max-height":_vm.height,"elevation":"0","color":"white","flat":""}},[_c('v-container',{staticClass:"py-0 my-n1 fill-height flex-nowrap"},[(!_vm.mobile)?[_c('Brand',{attrs:{"site-name":_vm.siteName,"domain":_vm.domain}}),(!_vm.$apollo.queries.me.loading)?_c('v-tabs',{attrs:{"value":_vm.activeDomain,"active-class":"font-weight-medium","slider-size":"3","slider-color":"primary","align-with-title":""}},_vm._l((_vm.links),function(link,i){return _c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(link.href),expression:"link.href"}],key:("navigation-" + i),staticClass:"body-1 px-2",style:({ width: link.width }),attrs:{"ripple":false,"href":link.href}},[_c(link.icon,{tag:"component",staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_c('v-badge',{attrs:{"value":(['/xxx']).includes(link.href),"color":"primary","content":"ใหม่"}},[_vm._v(" "+_vm._s(link.text)+" ")])],1)}),1):_vm._e(),_c('v-spacer'),(!_vm.$apollo.loading)?_c('div',{staticClass:"d-flex align-center gap-2"},[(_vm.isShipnity)?_c('v-btn',{staticClass:"body-1",attrs:{"href":("https://blog." + _vm.domain + "/how-to/"),"ripple":false,"target":"_blank","height":"40","rounded":"","text":"","link":""}},[_vm._v(" คู่มือ ")]):_vm._e(),_c('Help',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ripple":false,"width":"40","height":"40","icon":""}},'v-btn',attrs,false),on),[_c(_vm.helpIcon,{tag:"component",attrs:{"width":"24","height":"24"}})],1)]}}],null,false,7054344)}),(_vm.me.role.accessSetting)?_c('v-btn',{class:{
              'v-btn--active': _vm.activeSetting,
              'primary--text': _vm.activeSetting,
            },attrs:{"href":"/setting","ripple":false,"tag":"a","width":"40","height":"40","icon":""}},[_c('v-icon',[_vm._v(" mdi-cog ")])],1):_vm._e(),_c('Notify',{attrs:{"api-url":_vm.apiUrl,"items":_vm.notificationList.nodes,"loading":_vm.$apollo.queries.notificationList.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ripple":false,"width":"40","height":"40","icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.notificationList.nodesCount,"content":_vm.notificationList.nodesCount,"color":"error","overlap":"","bottom":""}},[_c(_vm.notificationsIcon,{tag:"component",attrs:{"width":"24","height":"24"}})],1)],1)]}}],null,false,2210027716)}),_c('Account',{attrs:{"domain":_vm.domain},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"body-1 font-weight-regular",attrs:{"ripple":false,"height":"60px","tag":"a","tile":"","text":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"text-truncate",style:({ maxWidth: '140px' })},[_vm._v(" "+_vm._s(_vm.me.shop.name)+" ")]),_c('v-icon',{attrs:{"color":"secondary","right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1652376027)})],1):_vm._e()]:[_c('a',{staticClass:"text-decoration-none font-weight-medium text-capitalize no-user-select secondary--text mr-1",attrs:{"href":"/","text":""}},[_vm._v(" "+_vm._s(_vm.siteName)+" ")]),_c('v-app-bar-nav-icon',{staticClass:"ml-1",attrs:{"color":"secondary","height":"40","width":"40"},on:{"click":function($event){return _vm.setDrawer(true)}}}),_c('v-spacer'),_c('div',{staticClass:"d-flex gap-1"},[_c('div',[_c('QuickMenu')],1),(_vm.me.role.accessSetting)?_c('v-btn',{attrs:{"href":"/setting/","ripple":false,"width":"40","height":"40","icon":""}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-cog ")])],1):_vm._e(),_c('div',[_c('Notify',{attrs:{"api-url":_vm.apiUrl,"items":_vm.notificationList.nodes,"loading":_vm.$apollo.queries.notificationList.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ripple":false,"width":"40","height":"40","icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.notificationList.nodesCount,"content":_vm.notificationList.nodesCount,"color":"error","overlap":"","bottom":""}},[_c(_vm.notificationsIcon,{tag:"component",attrs:{"width":"24","height":"24"}})],1)],1)]}}])})],1),_c('div',[_c('Account',{attrs:{"domain":_vm.domain},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ripple":false,"width":"40","height":"40","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-menu-down ")])],1)]}}])})],1)],1)]],2)],1),_c('v-navigation-drawer',{style:({
      zIndex: 300
    }),attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.siteName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.me.shop.name)+" ")])],1)],1),_c('v-divider')],1),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.links),function(link,i){return _c('v-list-item',{key:("navigation-drawer-" + i),attrs:{"href":link.href}},[_c('v-list-item-icon',[_c(link.icon,{tag:"component",attrs:{"width":"24","height":"24"}})],1),_c('v-list-item-title',[_c('v-badge',{attrs:{"value":(['/xxx']).includes(link.href),"color":"primary","content":"ใหม่"}},[_vm._v(" "+_vm._s(link.text)+" ")])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }