import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'ใน %s',
    past: '%sที่แล้ว',
    s: 'ไม่กี่วินาที',
    m: 'นาที',
    mm: '%d นาที',
    h: 'ชั่วโมง',
    hh: '%d ชั่วโมง',
    d: 'วัน',
    dd: '%d วัน',
    M: 'เดือน',
    MM: '%d เดือน',
    y: 'ปี',
    yy: '%d ปี'
  }
})
Vue.prototype.$dayjs = dayjs

export default dayjs
