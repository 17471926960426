import gql from 'graphql-tag'

export const DELETE_NOTI = gql`
  mutation ($input: DeleteNotificationInput!)  {
    deleteNotification(input: $input) {
      errors
    }
  }
`
export default {
  DELETE_NOTI
}
