import gql from 'graphql-tag'

export const GET_NOTI_LIST = gql`
  query ($page: Int, $perPage: Int) {
    notificationList(page: $page, perPage: $perPage) {
      hasNextPage
      hasPreviousPage
      nodes {
        id
        heading
        description
        createdAt
        linkName
        linkTo
        notifiableType
      }
      pagesCount
      nodesCount
    }
  }
`

export default {
  GET_NOTI_LIST
}
