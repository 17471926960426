<template>
  <v-menu
    :min-width="150"
    nudge-top="-4px"
    offset-y
    tile>
    <template #activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        width="40"
        height="40"
        icon
        v-on="on">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        :href="`/orders/new`"
        :ripple="false"
        class="body-2"
        link>
        <v-icon left>
          mdi-text-box-plus-outline
        </v-icon>
        สร้างออเดอร์
      </v-list-item>
      <v-list-item
        :href="`/expenses/new`"
        :ripple="false"
        class="body-2"
        link>
        <v-icon left>
          mdi-cash-plus
        </v-icon>
        ค่าใช้จ่าย
      </v-list-item>
      <v-list-item
        :href="`/returns/new`"
        :ripple="false"
        class="body-2"
        link>
        <v-icon left>
          mdi-cart-remove
        </v-icon>
        คืนสินค้า
      </v-list-item>
      <v-list-item
        :href="`/wastes/new`"
        :ripple="false"
        class="body-2"
        link>
        <v-icon left>
          mdi-basket-remove-outline
        </v-icon>
        สินค้าชำรุด
      </v-list-item>
      <v-list-item
        :href="`/histories/vat_invoices`"
        :ripple="false"
        class="body-2"
        link>
        <v-icon left>
          mdi-paper-roll-outline
        </v-icon>
        ออกใบกำกับภาษี
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  VMenu,
  VList,
  VListItem,
  VBtn,
  VIcon
} from 'vuetify/lib'

export default {
  components: {
    VMenu,
    VBtn,
    VIcon,
    VList,
    VListItem
  }
}
</script>

<style lang="scss" scoped>
  .v-menu__content {
    border-radius: 4px !important;
    box-shadow: 0px 0px 5px rgba(86,96,117,0.2) !important;
    > .v-list {
      ::v-deep {
        padding: 8px;
        .v-list-item {
          padding: 0 8px;
          margin-bottom: 4px;
          border-radius: 4px;
          &::before {
            border-radius: 4px;
          }
          &:hover::before {
            opacity: 0.1;
          }
          &.v-list-item--link:hover {
            color: var(--v-primary-base) !important;
            .v-icon.v-icon {
              color: var(--v-primary-base) !important;
            }
          }
          .v-list-item__title {
            font-size: 1rem !important;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
          }
        }
      }
    }
  }
</style>
