<template>
  <v-dialog
    v-model="dialog"
    content-class="noti-table__dialog mt-15"
    max-width="640"
    transition="fade-transition"
    persistent
    fullscreen
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="caption"
        color="primary"
        rounded
        text
        @click="$_.delay(on.click, 0, $event)">
        ดูแจ้งเตือนทั้งหมด
      </v-btn>
    </template>
    <v-sheet
      class="overflow-auto pa-3"
      color="background">
      <v-card
        max-width="640"
        class="mx-auto"
        rounded
        flat>
        <v-card-title>
          <div class="d-flex align-center gap-1">
            <v-btn
              x-small
              icon
              @click="setDialog(false)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div>
              การแจ้งเตือน
            </div>
          </div>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <div
            v-if="$apollo.loading && !countModifier"
            class="grey--text text-center">
            กำลังโหลด...
          </div>
          <v-virtual-scroll
            v-if="notificationList.nodes.length"
            :items="notificationList.nodes"
            height="500"
            item-height="94">
            <template v-slot:default="{ item, index }">
              <v-list-item
                :key="`noti-${item.id}`"
                :href="item.linkTo"
                :ripple="false"
                class="noti-table__card d-block"
                link>
                <div class="d-flex align-center gap-3 py-4 px-sm-2">
                  <v-avatar
                    v-if="item.notifiableType === 'ORDER'"
                    size="50"
                    color="success">
                    <component
                      :is="orderIcon"
                      :style="{ width: '32px' }"
                      class="white--text" />
                  </v-avatar>
                  <v-avatar
                    v-else-if="item.notifiableType === 'SUBPRODUCT'"
                    size="50"
                    color="warning">
                    <component
                      :is="shoppingIcon"
                      :style="{ width: '32px' }"
                      class="white--text"
                    />
                  </v-avatar>
                  <v-avatar
                    v-else
                    size="50"
                    color="secondary">
                    <component
                      :is="helpIcon"
                      :style="{ width: '32px' }"
                      class="white--text" />
                  </v-avatar>
                  <div class="flex-grow-1">
                    <div class="d-flex flex-wrap">
                      <div>
                        <div class="secondary--text">
                          {{ item.heading }}
                        </div>
                        <div class="grey--text caption">
                          {{ item.description }}
                        </div>
                      </div>
                      <v-spacer />
                      <v-btn
                        :href="item.linkTo"
                        target="_blank"
                        class="caption my-2"
                        color="primary"
                        depressed
                        rounded
                        small>
                        <v-icon left>
                          mdi-open-in-new
                        </v-icon>
                        {{ item.linkName }}
                      </v-btn>
                    </div>

                    <div class="d-flex flex-wrap caption">
                      <div class="primary--text">
                        {{ $dayjs(item.createdAt).fromNow() }}
                      </div>
                      <v-spacer />
                      <div class="grey--text">
                        {{ item.createdAt | formatDateTime }}
                      </div>
                    </div>
                  </div>
                  <div class="ml-4">
                    <DeleteNotifyDialog
                      :api-url="apiUrl"
                      :item-id="item.id"
                      :label="item.heading"
                      @removed="onRemoved($event)" />
                  </div>
                </div>
              </v-list-item>
              <infinite-loading
                v-if="index === (notificationList.nodes.length - 1)"
                :key="`noti-loading`"
                :identifier="infiniteId"
                @infinite="infiniteHandler($event)">
                <div slot="no-more" />
                <div slot="no-results" />
              </infinite-loading>
            </template>
          </v-virtual-scroll>
          <div
            v-else
            class="d-flex justify-center align-center grey--text py-5">
            ไม่มีการแจ้งเตือน
          </div>
        </v-card-text>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<script>
import {
  VSheet,
  VDialog,
  VIcon,
  VAvatar,
  VBtn,
  VCard,
  VCardTitle,
  VCardText,
  VSpacer,
  VVirtualScroll,
  VListItem
} from 'vuetify/lib'
import InfiniteLoading from 'vue-infinite-loading'
import { GET_NOTI_LIST } from '../../../resources/graphql'
import DeleteNotifyDialog from './DeleteNotifyDialog.vue'
import orderIcon from '../../../assets/svg/icons/order.svg'
import shoppingIcon from '../../../assets/svg/icons/shopping-basket.svg'
import helpIcon from '../../../assets/svg/icons/help.svg'

export default {
  components: {
    VSheet,
    InfiniteLoading,
    VDialog,
    VIcon,
    VAvatar,
    VBtn,
    VCard,
    VCardTitle,
    VCardText,
    VSpacer,
    VListItem,
    VVirtualScroll,
    DeleteNotifyDialog
  },
  props: {
    apiUrl: {
      type: String,
      default: undefined
    },
    value: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  apollo: {
    notificationList: () => ({
      query: GET_NOTI_LIST,
      variables () {
        return {
          page: 1,
          perPage: this.perPage,
          uri: this.apiUrl
        }
      },
      result () {
        this.countModifier += 1
        this.infiniteId += 1
      }
    })
  },
  data () {
    return {
      dialog: false,
      page: 1,
      perPage: 10,
      notificationList: {
        hasNextPage: 0,
        nodes: [],
        pagesCount: 0,
        nodesCount: 0
      },
      countModifier: 0,
      infiniteId: +new Date(),
      orderIcon,
      shoppingIcon,
      helpIcon
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (dialog) {
        this.dialog = dialog
      }
    }
  },
  methods: {
    async infiniteHandler ($state) {
      if (this.notificationList.hasNextPage) {
        this.page += 1
        await this.$apollo.queries.notificationList.fetchMore({
          variables: {
            page: this.page,
            perPage: this.perPage,
            uri: this.apiUrl
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const { hasNextPage, hasPreviousPage, pagesCount, nodesCount, nodes } = fetchMoreResult.notificationList
            return ({
              notificationList: {
                __typename: previousResult.notificationList.__typename,
                nodes: this.$_.uniqBy([
                  ...previousResult.notificationList.nodes,
                  ...nodes
                ], 'id'),
                hasPreviousPage,
                hasNextPage,
                pagesCount,
                nodesCount
              }
            })
          }
        })
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    async onRemoved ({ id }) {
      await this.$apollo.queries.notificationList.fetchMore({
        variables: {
          page: this.page,
          perPage: this.perPage,
          uri: this.apiUrl
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { hasNextPage, hasPreviousPage, pagesCount, nodesCount } = fetchMoreResult.notificationList
          const nodes = previousResult.notificationList.nodes.filter((item) => item.id !== id)
          return ({
            notificationList: {
              __typename: previousResult.notificationList.__typename,
              nodes,
              hasPreviousPage,
              hasNextPage,
              pagesCount,
              nodesCount
            }
          })
        }
      })
    },
    changeDialog (dialog = false) {
      console.log(dialog)
      if (dialog) {
        this.$vuetify.goTo(0)
      }
      this.$emit('input', dialog)
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style lang="scss">
  .noti-table__dialog {
    &.v-dialog--animated {
      animation: none !important;
    }
    &.v-dialog--fullscreen {
      height: calc(100vh - 60px);
    }
  }
</style>

<style lang="scss" scoped>
  .noti-table__card::before {
    border-radius: 8px;
  }
</style>
