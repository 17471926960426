<template>
  <v-menu
    :min-width="150"
    nudge-top="-4px"
    offset-y
    tile>
    <template v-slot:activator="{ attrs, on }">
      <div
        v-bind="attrs"
        class="d-flex align-center fill-height font-weight-medium text-capitalize no-user-select"
        v-on="on">
        {{ siteName }}
      </div>
    </template>
    <v-list dense>
      <v-list-item
        :href="`/orders/new`"
        :ripple="false"
        class="body-2"
        link>
        <v-icon
          size="20"
          left>
          mdi-plus
        </v-icon>
        Order
      </v-list-item>
      <v-list-item
        :href="`/expenses/new`"
        :ripple="false"
        class="body-2"
        link>
        <v-icon
          size="20"
          left>
          mdi-plus
        </v-icon>
        ค่าใช้จ่าย
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  VMenu,
  VList,
  VListItem,
  VIcon
} from 'vuetify/lib'

export default {
  components: {
    VMenu,
    VIcon,
    VList,
    VListItem
  },
  props: {
    siteName: {
      type: String,
      default: 'Untitled'
    },
    domain: {
      type: String,
      default: 'Untitled'
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-menu__content {
    border-radius: 4px !important;
    box-shadow: 0px 0px 5px rgba(86,96,117,0.2) !important;
  }
</style>
