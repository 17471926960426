import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import Alert from './components/Alert.vue'
import Snackbar from './components/Snackbar.vue'

export default class Notification {
  constructor () {
    this.vue = Vue
  }

  open (propsData) {
    if (propsData.mode === 'alert') {
      return new (this.vue.extend(Alert))({
        el: document.createElement('div'),
        propsData,
        vuetify
      })
    }
    return new (this.vue.extend(Snackbar))({
      el: document.createElement('div'),
      propsData,
      vuetify
    })
  }

  success (message, options = {}) {
    return this.open({
      message,
      mode: 'snackbar',
      type: 'success',
      icon: 'mdi-check-circle-outline',
      color: vuetify.preset.theme.themes.light.success,
      ...options
    })
  }

  error (message, options = {}) {
    return this.open({
      message,
      mode: 'snackbar',
      type: 'error',
      icon: 'mdi-close-circle-outline',
      color: vuetify.preset.theme.themes.light.error,
      ...options
    })
  }

  info (message, options = {}) {
    return this.open({
      message,
      mode: 'snackbar',
      type: 'info',
      icon: 'mdi-information-outline',
      color: vuetify.preset.theme.themes.light.primary,
      ...options
    })
  }

  warning (message, options = {}) {
    return this.open({
      message,
      mode: 'snackbar',
      type: 'warning',
      icon: 'mdi-alert-outline',
      color: vuetify.preset.theme.themes.light.warning,
      ...options
    })
  }

  default (message, options = {}) {
    return this.open({
      message,
      mode: 'snackbar',
      type: 'default',
      icon: 'mdi-bell',
      ...options
    })
  }
}
