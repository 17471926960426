import _ from 'lodash'
import Vue from 'vue'
import vueCustomElement from 'vue-custom-element'
import { SntNavbar } from '@/components/SntNavbar'
import { SntBubble } from '@/components/SntBubble'
import vuetify from '@/plugins/vuetify'
import apolloProvider from '@/plugins/vue-apollo'
import * as filters from './filters'
import '@/plugins/lodash'
import '@/plugins/dayjs'

_.forEach(filters, (value, key) => {
  Vue.filter(key, filters[key])
})
Vue.config.ignoredElements = [
  'snt-navbar',
  'snt-bubble'
]
Vue.config.productionTip = false
Vue.use(vueCustomElement)

SntNavbar.apolloProvider = apolloProvider
SntNavbar.vuetify = vuetify
Vue.customElement('snt-navbar', SntNavbar, {
  shadow: false
})
SntBubble.apolloProvider = apolloProvider
SntBubble.vuetify = vuetify
Vue.customElement('snt-bubble', SntBubble, {
  shadow: false
})
