import _ from 'lodash'
import PhoneNumber from 'awesome-phonenumber'

export const toDashEmpty = (value) => value || '-'

export const toLocaleString = (value, digits = 2) => {
  if (!_.isNumber(value)) return value
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: digits
  })
}

export const toLocaleStringDecimal = (value, digits = 2) => {
  if (!value) return '0.00'
  const number = Number(value)
  return _.isNaN(number) ? '0.00' : number.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}

export const toLocaleStringDecimalBySum = (list = []) => {
  if (!list.length || _.every(list, null) || _.every(list, undefined)) { return '-' }
  const sum = _.chain(list)
    .filter((it) => it)
    .map((it) => parseFloat(it))
    .sum()
    .value()
  return toLocaleStringDecimal(sum)
}

/**
 * @name BAHTTEXT.js
 * @version 1.1.5
 * @update May 1, 2017
 * @website: https://github.com/earthchie/BAHTTEXT.js
 * @author Earthchie http://www.earthchie.com/
 * @license WTFPL v.2 - http://www.wtfpl.net/
 * */
export const toLocaleStringCurrency = (value, suffix = 'บาทถ้วน') => {
  let num = value
  num = num || 0
  num = num.toString().replace(/[, ]/g, '')

  if (_.isNaN(num) || (Math.round(parseFloat(num) * 100) / 100) === 0) {
    return 'ศูนย์บาทถ้วน'
  }

  const t = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']
  const n = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า']
  let digit
  let text = ''
  let parts
  let i

  if (num.indexOf('.') > -1) {
    parts = num.toString().split('.')

    num = parts[0]
    parts[1] = parseFloat(`0.${ parts[1]}`)
    parts[1] = (Math.round(parts[1] * 100) / 100).toString() // more accurate than toFixed(2)
    parts = parts[1].split('.')

    if (parts.length > 1 && parts[1].length === 1) {
      parts[1] = `${parts[1].toString() }0`
    }

    num = parseInt(num, 10) + parseInt(parts[0], 10)
    text = num ? toLocaleStringCurrency(num) : ''

    if (parseInt(parts[1], 10) > 0) {
      text = text.replace('ถ้วน', '') + toLocaleStringCurrency(parts[1], 'สตางค์')
    }

    return text
  }
  if (num.length > 7) {
    const overflow = num.substring(0, num.length - 6)
    const remains = num.slice(-6)
    return toLocaleStringCurrency(overflow).replace('บาทถ้วน', 'ล้าน') + toLocaleStringCurrency(remains).replace('ศูนย์', '')
  }
  const len = num.length
  for (i = 0; i < len; i += 1) {
    digit = parseInt(num.charAt(i), 10)
    if (digit > 0) {
      if (len > 2 && i === len - 1 && digit === 1 && suffix !== 'สตางค์') {
        text += `เอ็ด${ t[len - 1 - i]}`
      } else {
        text += n[digit] + t[len - 1 - i]
      }
    }
  }
  text = text.replace('หนึ่งสิบ', 'สิบ')
  text = text.replace('สองสิบ', 'ยี่สิบ')
  text = text.replace('สิบหนึ่ง', 'สิบเอ็ด')

  return text + suffix
}

export const toLocaleStringTel = (value) => {
  const phone = PhoneNumber(value || '', 'TH').toJSON()
  if (!phone.valid) return value
  return phone.number.national.replaceAll(' ', '-')
}

export default {
  toLocaleString,
  toLocaleStringDecimal,
  toLocaleStringCurrency,
  toLocaleStringTel,
  toDashEmpty
}
