<template>
  <v-fade-transition>
    <v-card
      v-if="isActive"
      width="450"
      class="snt-alert__item snt-alert__item--center"
      flat>
      <v-card-title />
      <v-card-text class="text-center">
        <v-icon
          :color="color"
          class="mb-8"
          size="80">
          {{ icon }}
        </v-icon>
        <div class="title secondary--text mb-1">
          {{ message }}
        </div>
        <div
          v-if="description"
          class="grey--text body-2 mb-6">
          {{ description }}
        </div>
      </v-card-text>
      <v-card-actions />
    </v-card>
  </v-fade-transition>
</template>

<script>
import Vue from 'vue'
import { VCard, VFadeTransition, VIcon } from 'vuetify/lib'

export default Vue.extend({
  components: { VCard, VIcon, VFadeTransition },
  props: {
    type: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: 'mdi-bell'
    },
    color: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    timeout: {
      type: Number,
      default: 3000
    }
  },
  data () {
    return {
      isActive: true
    }
  },
  computed: {
    transition () {
      return {}
    }
  },
  beforeMount () {
    this.setupContainer()
  },
  mounted () {
    this.show()
  },
  methods: {
    show () {
      if (this.shouldQueue()) {
        this.queueTimer = setTimeout(this.show, 250)
        return
      }
      this.parentTop.insertAdjacentElement('afterbegin', this.$el)
      this.isActive = true
      setTimeout(this.dismiss, this.timeout)
    },
    shouldQueue () {
      return this.parentTop.childElementCount > 0
    },
    setupContainer () {
      this.parentTop = document.querySelector('.snt-alert')
      if (this.parentTop) return
      if (!this.parentTop) {
        this.parentTop = document.createElement('div')
        this.parentTop.className = 'snt-alert'
      }
      const container = document.querySelector('.v-application--wrap')
      container.appendChild(this.parentTop)
    },
    dismiss () {
      clearTimeout(this.queueTimer)
      this.isActive = false
      setTimeout(() => {
        this.$destroy()
        if (typeof this.$el.remove !== 'undefined') {
          this.$el.remove()
        } else {
          this.$el.parentNode.removeChild(this.$el)
        }
      }, 150)
    }
  }
})
</script>

<style lang="scss">
  .snt-alert{
    position:fixed;
    display:flex;
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding:2em;
    overflow:hidden;
    z-index:1052;
    pointer-events:none;
    &__item {
      align-items:center;
      padding: 4px 16px;
      animation-duration:150ms;
      margin:.5em 0;
      box-shadow: 0px 22.59px 63.2px rgba(19, 10, 46, 0.08),
        0px 6.02px 24.09px rgba(19, 10, 46, 0.07),
        0px 2.26px 10.54px rgba(19, 10, 46, 0.25),
        0px 0.75px 2.26px rgba(19, 10, 46, 0.13) !important;
      border-radius:.25em;
      pointer-events:auto;
      color:#fff;
      min-height:3em;
      &--center {
        align-self:center;
        margin: 0 auto;
      }
    }
  }
</style>
