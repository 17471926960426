<template>
  <v-dialog
    v-model="dialog"
    width="480px"
    scrollable>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :loading="submitting"
        x-small
        icon
        v-on="on"
        @click.prevent>
        <v-icon
          color="#C8C8C8"
          size="16">
          mdi-close
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-btn
          x-small
          icon
          @click="setDialog(false)">
          <v-icon color="#A9A9A9">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="text-center mb-4">
          <component
            :is="trashIcon"
            class="error--text"
            width="60"
            height="60" />
        </div>
        <div class="text-center mb-2">
          คุณต้องการลบข้อความแจ้งเตือน?
        </div>
        <div class="grey--text text-center mb-8">
          ระบบจะข้อความแจ้งเตือน "{{ label }}"
        </div>
        <v-divider />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="px-3"
          color="primary"
          rounded
          text
          @click="setDialog(false)">
          ยกเลิก
        </v-btn>
        <v-btn
          :loading="submitting"
          class="px-3"
          color="error"
          depressed
          rounded
          @click="submit()">
          ลบข้อมูล
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VDialog,
  VIcon,
  VBtn,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VDivider
} from 'vuetify/lib'
import { DELETE_NOTI } from '../../../resources/graphql'
import trashIcon from '../../../assets/svg/icons/trash.svg'

export default {
  components: {
    VDialog,
    VIcon,
    VBtn,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VSpacer,
    VDivider
  },
  props: {
    apiUrl: {
      type: String,
      default: undefined
    },
    itemId: {
      type: [Number, String],
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      trashIcon
    }
  },
  methods: {
    async submit () {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: DELETE_NOTI,
        variables: {
          uri: this.apiUrl,
          input: {
            ids: [this.itemId]
          }
        }
      })
      this.submitting = false
      this.$emit('removed', { id: this.itemId })
      this.setDialog(false)
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }

}
</script>
