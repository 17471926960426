import Vue from 'vue'
import VueApollo from 'vue-apollo'
import Cookies from 'js-cookie'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { logErrorMessages } from '@vue/apollo-util'
import Notification from '@/services/notification'

Vue.use(VueApollo)
const notify = new Notification()
const httpLink = createUploadLink({
  uri: process.env.VUE_APP_API,
  credentials: false
})

const authLink = setContext(({ headers, variables }) => {
  const token = Cookies.get('CSRF-TOKEN') || null
  return {
    uri: variables?.uri || process.env.VUE_APP_API,
    headers: {
      ...headers,
      'X-CSRF-Token': token
    }
  }
})

const errorLink = onError((error) => {
  notify.error('ไม่สามารถเชื่อมต่อกับเซิฟเวอร์ได้')
  if (process.env.NODE_ENV !== 'production') {
    logErrorMessages(error)
  }
})

export const apolloClient = new ApolloClient({
  link: errorLink
    .concat(authLink)
    .concat(httpLink),
  cache: new InMemoryCache()
})

const apollo = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {}
})

export default apollo
